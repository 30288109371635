import * as React from "react";

const SvgMidtypeCombined = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 700 250" {...props}>
    <defs>
      <linearGradient
        x1="75.448%"
        y1="5.095%"
        x2="24.624%"
        y2="93.82%"
        id="MidtypeCombined_svg__a"
      >
        <stop stopColor="#5F49D7" offset="0%" />
        <stop stopColor="#3123AE" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M225.292 167.588V76.741h25.92l12.833 45.614c3.176 11.436 5.717 24.523 5.717 24.523h.254s2.415-13.087 5.591-24.523l12.833-45.614h26.301v90.847h-17.153v-52.094c0-7.37.763-18.805.763-18.805h-.255s-2.032 10.673-3.938 17.28l-15.629 53.62h-17.66l-15.248-53.62c-1.906-6.607-3.939-17.28-3.939-17.28h-.254s.763 11.436.763 18.805v52.094h-16.9zm102.028 0v-65.18h17.28v65.18h-17.28zm0-75.346v-15.5h17.28v15.5h-17.28zm52.856 77.252c-16.39 0-27.571-13.341-27.571-34.433 0-20.33 11.562-34.433 27.826-34.433 9.148 0 14.865 4.193 18.423 9.657h.381V76.74h17.28v90.847h-16.644v-8.513h-.255c-3.811 6.226-10.291 10.42-19.44 10.42zm3.812-14.485c10.292 0 15.628-7.623 15.628-19.567 0-12.451-4.065-20.837-15.12-20.837-9.529 0-14.357 8.386-14.357 20.456 0 12.579 4.955 19.948 13.85 19.948zm39.897-41.167v-11.435h9.02v-20.33h16.9v20.33h11.18v11.435h-11.18v34.56c0 4.829 2.668 6.48 6.48 6.48 2.16 0 5.082-.127 5.082-.127v12.706s-3.939.254-10.8.254c-8.386 0-17.661-3.303-17.661-15.628v-38.245h-9.021zm47.393 75.092V175.34h6.098c6.862 0 9.657-3.558 9.657-8.894 0-3.558-1.27-7.75-4.955-17.661l-17.534-46.377h18.169l9.656 29.35c2.033 5.972 4.193 14.231 4.193 14.231h.254s1.78-8.259 3.812-14.23l9.148-29.35h17.408L504.44 169.24c-4.955 14.485-9.784 19.694-22.998 19.694h-10.164zm61.75 0v-86.527h16.645v8.132h.381c4.193-5.718 10.292-9.91 19.186-9.91 17.28 0 28.334 14.103 28.334 34.432 0 21.092-11.435 34.433-28.207 34.433-9.148 0-15.12-3.685-18.805-9.275h-.254v28.715h-17.28zm32.654-33.798c8.767 0 14.358-7.242 14.358-19.567 0-12.324-4.32-20.964-14.993-20.964-10.8 0-15.12 9.275-15.12 20.964 0 11.817 5.464 19.567 15.755 19.567zm70.518 14.358c-21.6 0-33.798-15.12-33.798-34.56 0-19.313 13.087-34.306 32.146-34.306 20.965 0 32.146 16.01 32.146 39.261h-47.266c1.27 10.038 6.861 16.264 16.645 16.264 6.734 0 10.673-3.05 12.579-8.005h17.026c-2.414 11.563-12.706 21.346-29.478 21.346zm-1.398-55.525c-9.148 0-13.595 5.464-15.12 13.977h28.97c-.508-8.259-5.972-13.977-13.85-13.977z"
        fill="#3123AE"
        fillRule="nonzero"
      />
      <g transform="translate(16 37)">
        <circle fill="#FFF" cx={88.529} cy={88.529} r={76.765} />
        <path
          d="M88.53 172.353c-46.295 0-83.824-37.53-83.824-83.824S42.235 4.706 88.529 4.706c46.295 0 83.824 37.529 83.824 83.823 0 46.295-37.53 83.824-83.824 83.824zM74.758 43.029l-45.347 78.543 7.574 4.373 45.346-78.543-7.573-4.373zM92.43 53.232l-32.39 56.102 7.573 4.373 32.39-56.102-7.573-4.373zm16.41 9.474L63.494 141.25l7.574 4.373 45.346-78.543-7.573-4.373zm30.628-12.237L94.122 129.01l7.574 4.373 45.347-78.543-7.574-4.372z"
          fill="url(#MidtypeCombined_svg__a)"
        />
      </g>
    </g>
  </svg>
);

export default SvgMidtypeCombined;
