import React from 'react';

import CombinedLogo from '../icons/MidtypeLogo';

interface IProps {
  width?: number;
}

const Logo: React.FC<IProps> = props => {
  const w = props.width || 120;
  const h = w / 2.75;
  return <CombinedLogo width={`${w}px`} height={`${h}px`} />;
};

export default Logo;
